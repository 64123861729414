.handling_days {
  display: inline-block;
  width: 32px;
  height: 20px;
  margin-right: 5px;
  vertical-align: top;
}

.handling_days > span {
  display: inline-block;
  width: 11%;
  height: 100%;
  margin-right: 1px;
}

.handling_days .days0,
.handling_days .days1 {
  background: green;
}

.handling_days .days2,
.handling_days .days3 {
  background: lime;
}

.handling_days .days4 {
  background: yellow;
}

.handling_days .days5 {
  background: orange;
}

.handling_days .days6 {
  background: red;
}

.handling_text {
  vertical-align: middle;

  &.red {
    color: red;
  }
}
