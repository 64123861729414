@import "@angular/material/theming";
@import "./var-helpers";

// Utilities
@import "../partials/utilities";
// Cards
@import "../partials/cards";
// Tabs
@import "../partials/tabs";
// Quill
@import "../partials/plugins/quill";
// Angular Calendar
@import "../partials/plugins/angular-calendar";
// Mat Table
@import "../partials/table";
// Mat changes
@import "../partials/mat";

@import "../partials/report-panel";

@import "../partials/handling_days";

// Mat color changes
$mat-background-color-disabled: rgba(0, 0, 0, .1) !default;
$mat-text-color-disabled: rgba(0, 0, 0, .7) !default;
$mat-text-background-color: rgba(0, 0, 0, .04) !default;
$mat-text-color: rgba(0, 0, 0, .87) !default;
$mat-text-background-color-inverted: rgba(0, 0, 0, .87) !default;
$mat-text-color-inverted: rgba(255, 255, 255, 1) !default;
$mat-text-label-color: rgba(0, 0, 0, .6) !default;
$mat-divider-color: rgba(0, 0, 0, .1) !default;
$table-deleted-row-background-color: rgb(255, 243, 243) !default;
$table-deleted-row-text-color: rgba(0, 0, 0, .4) !default;
$table-disabled-row-background-color: rgba(200, 200, 200, .1) !default;
$table-disabled-row-text-color: rgba(0, 0, 0, .4) !default;
$table-divider-color: rgba(0, 0, 0, .1) !default;

$mat-button-default-text-color: #333333 !default;
$mat-button-default-background-color: #cccccc !default;
$mat-button-black-text-color: #ffffff !default;
$mat-button-black-background-color: #333333 !default;

// Sidebar
$sidebar-background: $theme-background !default;
$sidebar-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .40) !default;
@import "../../shared/sidebar/sidebar.directive.theme";
@import "../../shared/report-panel/report-panel.directive.theme";

// Sidenav
$sidenav-background: #283144 !default;
$sidenav-color: white !default;
$sidenav-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .40) !default;
$sidenav-toolbar-background: #fff !default;
$sidenav-logo-color: white !default;
$sidenav-secondary-text: $light-secondary-text !default;
$sidenav-collapsed-hover-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .40) !default;

@import "../../../app/layout/sidenav/sidenav.component.theme";

// Sidenav Item
$sidenav-item-color: $sidenav-color !default;
$sidenav-item-color-hover: white !default;
$sidenav-item-color-active: $theme-color-primary-contrast !default;

$sidenav-item-background-hover: darken($sidenav-background, 2.5) !default;
$sidenav-item-background-active: $theme-color-primary !default;
$sidenav-item-text-icon-color: rgba(white, 0.5) !default;

$sidenav-item-typography-font-family: var(--font-family) !default;
$sidenav-item-typography-font-size: 14px !default;
$sidenav-item-typography-font-weight: 400 !default;

$sidenav-item-subheading-color: $light-secondary-text !default;
$sidenav-item-subheading-typography-font-family: var(--font-family) !default;

$sidenav-item-dropdown-level1: darken($sidenav-background, 3.5) !default;
$sidenav-item-dropdown-level2: darken($sidenav-background, 5) !default;
$sidenav-item-dropdown-level3: darken($sidenav-background, 7.5) !default;
$sidenav-item-dropdown-level4: darken($sidenav-background, 10) !default;

@import "../../../app/layout/sidenav/sidenav-item/sidenav-item.component.theme";

// Toolbar
$toolbar-background: $theme-card !default;
$toolbar-color: $theme-text !default;
$toolbar-elevation: 1 !default;
$toolbar-logo-color: $theme-text !default;

$toolbar-button-separator-color: rgba(0, 0, 0, 0.08) !default;

$toolbar-user-dropdown-elevation: $elevation !default;
$toolbar-notification-elevation: $elevation !default;

$toolbar-search-background: $theme-app-bar !default;
$toolbar-search-dropdown-elevation: $elevation !default;

@import "../../../app/layout/toolbar/toolbar.component.theme";

// Navigation
@import "../../../app/layout/navigation/navigation.component.theme";

// Card
@import "../../shared/card/card.component.theme";

// Page Layout
$page-layout-header-background: #f5f5f5 !default;
$page-layout-header-border-color: rgba(215, 215, 215, 1) !default;
$page-layout-header-input-background: rgba(255, 255, 255, 1) !default;
$page-layout-header-input-border: rgba(0, 0, 0, 1) !default;
@import "../../shared/page-layout/page-layout.directive.theme";

// Backdrop
@import "../../shared/backdrop/backdrop.directive.theme";

// Breadcrumbs
@import "../../shared/breadcrumbs/breadcrumbs.component.theme";

// Widgets
@import "../../shared/alert-box/alert-box.component.theme";

// Calendar Presets
@import "../../shared/calendar/presets/presets.component.theme";

// loading overlay
$loading-overlay-background: rgba(255, 255, 255, .60) !default;
@import "../../shared/loading-overlay/loading-overlay.component.theme";

// knowledge base articles
$link-color: blue !default;
@import "../../../app/pages/knowledge-base/index/index.component.theme";

$leaflet-default-marker-color: rgba(0, 0, 0, .8) !default;
$leaflet-marker-shadow: rgba(0, 0, 0, .4) !default;
$leaflet-tools-shadow: rgba(0, 0, 0, .2) !default;
@import "../partials/leaflet.theme";

@import "../../shared/page-header-buttons/page-header-buttons.component.theme";

@import "../partials/table.theme";

@import "../partials/mat.theme";

@import "../partials/report-panel.theme";

@import "../../../app/pages/reports/editor/removable-category/removable-category.component.theme";
@import "../../../app/pages/archive/archive/view/view.component.theme";
