@import "var";

.mat-table {
  width: 100%;

  &.statistics {
    border-collapse: collapse;

    .mat-header-row {
      border: 0;
      border-bottom-width: 1px;
      border-bottom-style: solid;

      .mat-cell {
        font-size: 13px;
        line-height: 15.75px;
        font-weight: 500;
        text-align: left;

        &.center {
          text-align: center;
        }

        &:last-child {
          text-align: right;
        }
      }
    }

    .mat-row {
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }

    .mat-cell {
      padding: 10px 8px;

      &:first-child {
        text-align: left;
        padding-left: 20px;
      }

      &:last-child {
        text-align: right;
        padding-right: 28px;
      }

      &.space {
        padding-left: 40px;
      }

      &.double-space {
        padding-left: 60px;
      }

      &.center {
        text-align: center;
      }
    }

    .mat-footer-row {
      border: 0;

      .mat-cell {
        font-size: 13px;
        line-height: 15.75px;
        font-weight: 500;
      }
    }
  }

  .mat-sort-header-container {
    flex: 1 1 100%;
    align-items: flex-start;
    max-width: 100%;

    .mat-sort-header-arrow {
      position: absolute;
      top: 0;
      right: 0;
      background: transparent;
      padding: 6px;
      margin: 0 !important;

      .mat-sort-header-stem {
        padding: 1px;
        margin: -5px 0 0 -1px;
      }
    }

    .mat-sort-header-content {
      flex-direction: column;
      flex: 1 1 100%;
      align-items: flex-start;
      max-width: 100%;

      .header-content {
        font-size: 13px;
        line-height: 15.75px;
        font-weight: 500;
      }
    }
  }

  .mat-header-row .mat-header-cell .mat-sort-header-button {
    text-transform: uppercase;
  }

  .mat-row {
    .mat-cell {
      padding: 0 8px;
    }
  }

  .mat-cell.image-cell, .mat-header-cell.image-cell {
    flex: 0;
    width: 35px + $spacing;

    img {
      border-radius: 50%;
      height: 35px;
      width: 35px;
      margin-right: $spacing;
      vertical-align: middle;
    }
  }

  .mat-cell, .mat-header-cell {
    white-space: nowrap;
    padding: 10px 8px;
  }

  .mat-cell {
    & > span {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;

      &:not(.mat-checkbox) {
        overflow: visible !important;
        width: 100%;
      }

      .inline-edit {
        font-size: 18px;
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
        margin-right: 5px;
      }

      mat-form-field {
        width: 100%;

        .mat-form-field-wrapper {
          padding-bottom: 0;

          .mat-form-field-flex {
            padding-top: 0;
            padding-right: 0;

            .mat-form-field-suffix {
              align-self: center;
            }
          }

          .mat-form-field-underline {
            bottom: 0;
          }
        }
      }
    }
  }

  .mat-header-cell {
    vertical-align: top;

    .is-filtered {
      .mat-form-field {
        max-width: calc(100% - 20px);

        .mat-form-field-underline {
          width: calc(100% + 20px);
        }
      }

      .mat-icon-button {
        width: 20px;
      }
    }
  }

  .mat-cell.actions-cell, .mat-header-cell.actions-cell {
    flex: 0;
    width: 40px;
    overflow: visible;
  }

  mtx-grid-cell .mat-icon-button {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .paginator {
    display: block;
  }

  .mat-column-is_active,
  .mat-column-is_visible {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
  }

  .mat-column-created_at,
  .mat-column-number,
  .mat-column-reports {
    width: 95px;
    min-width: 95px;
    max-width: 95px;
  }

  .mat-column-reports {
    justify-content: flex-end;
  }

  .mat-column-actions {
    white-space: nowrap;
    text-align: right;
    flex: 0 1 80px;
    align-items: center;
    justify-content: end;

    &.b1 {
      width: 60px;
      min-width: 60px;
      max-width: 60px;
    }

    &.b2 {
      width: 105px;
      min-width: 105px;
      max-width: 105px;
    }

    &.b3 {
      width: 145px;
      min-width: 145px;
      max-width: 145px;
    }

    &.b4 {
      width: 175px;
      min-width: 175px;
      max-width: 175px;
    }
  }

  .mat-icon {
    vertical-align: middle;
  }
}

.table-paginator {
  .mat-form-field {
    margin-top: 0;

    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-form-field-flex {
        padding-top: .45em;

        .mat-form-field-infix {
          width: auto;
          border-top: 0;

          .mat-select-arrow-wrapper {
            transform: none;
          }
        }
      }
    }

    .mat-form-field-underline {
      bottom: 0;
    }
  }

  .mat-paginator-page-size-label {
    white-space: nowrap;
  }
}

.mat-row.deleted,
.mat-row.deleted .mat-cell,
.mat-row.anonymized,
.mat-row.anonymized .mat-cell,
tr.deleted,
tr.deleted td,
td.deleted,
.mat-row.disabled,
.mat-row.disabled .mat-cell,
tr.disabled,
tr.disabled td,
td.disabled {
  font-style: italic;
}

.clickable {
  cursor: pointer;
}

table {
  .is-filtered {
    .mat-form-field-underline {
      width: calc(100% + 40px);
    }
  }
}

table.mat-table {
  border-bottom: 0;
  table-layout: fixed;

  .mat-header-row,
  .mat-row {
    height: 41px;
  }

  .mat-cell {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.mat-column-expand {
  width: 30px;
  min-width: 30px;
  max-width: 30px;
}

mat-table mat-row.table-expand-row {
  border-bottom: 0;
}

table.mat-table .mat-row.table-expand-row {
  td {
    border-bottom: 0;
  }
}

mat-table mat-row.table-expand-details-row,
table.mat-table .mat-row.table-expand-details-row {
  height: 0;
  min-height: 0;

  div {
    overflow: hidden;
    white-space: normal;

    .table-expand-details-content {
      padding: 10px 0
    }
  }
}

.mat-no-data-row {
  &.no-mat {
    display: table-row;
    position: relative;

    .mat-cell {
      text-align: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

.report-status-icon {
  max-width: 24px;
  max-height: 24px;
  width: 24px;
  height: 24px;
}
